import React from 'react';

function ResumePage() {
  return (
    <section id='resume-section' className='resume-section'>
      <h2>Reach out if you have any questions!</h2>
      <h3>Temporarily Disabled</h3>
    </section>
  );
}

export default ResumePage;
